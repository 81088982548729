.separator {
  width: 100%;
  overflow: hidden;
  padding: 0px !important;
  margin: 0px !important;
  line-height: 0; /* Prevent image line-height spacing */
}

.separator-image {
  width: 100%;
  height: auto;
  max-height: 40px;
  display: block; /* Essential to remove inline spacing */
  object-fit: cover;
  vertical-align: middle; /* Ensures no vertical gaps */
}
