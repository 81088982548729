/* src/components/Evolution.css */

.evolution {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
}

.evolution-title {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
  display: inline-block;
  white-space: normal;  /* Allow the text to wrap */
  word-wrap: break-word; /* Ensure that long words wrap correctly */
}

.evolution-image {
  width: 60%;
  padding-bottom: 30px;
  padding-top: 10px;
  display: block;
  margin: 0 auto;
  transition: transform 0.1s ease-out;
  will-change: transform;
}
