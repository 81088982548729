/* src/components/ProjectCard.css */

.project-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0; /* Add vertical margin to separate cards */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for expansion */
  width: 280px; /* Initial width for cards */
  text-align: center;
  position: relative;
  overflow: hidden; /* Prevent content overflow */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-card.expanded {
  width: 340px; /* Increase width when expanded */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 8px;
  margin-bottom: 10px;
}

.project-title {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.project-description {
  font-size: 1rem;
  color: #555;
}

.project-extra {
  font-size: 0.9rem;
  color: #777;
  margin-top: 10px;
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 0; /* Collapse content by default */
}

.project-card.expanded .project-extra {
  max-height: 1000px; /* Expand content when card is expanded */
}

.project-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  text-align: center;
}

.project-button:hover {
  background-color: #0056b3;
}
