.marquee {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    background-color: #FFF; /* White background */
    color: #D56F5F; /* Dark coral font color */
    font-family: 'League Spartan', sans-serif;
    padding: 10px 0;
    position: relative;
    width: 100%;
}

.marquee-content {
    display: flex;
    animation: scroll 40s linear infinite;
    min-width: 200%;
}

.marquee-item {
    display: flex; /* Align logo with text */
    align-items: center;
    gap: 10px; /* Space between logo and text */
    font-size: 1.8rem;
    text-transform: uppercase;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 80px;
    padding-right: 80px;
    margin-left: 80px;
    margin-right: 80px;
}

.marquee-logo {
    width: 50px;
    height: 50px;
    object-fit: contain; /* Ensures logo scales nicely */
    border-radius: 20%; /* Rounded borders for logos */
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}
