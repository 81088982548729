/* src/components/FeaturedSection.css */

.featured-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 0; /* Remove gap to eliminate white space */
}
/* src/components/FeaturedSection.css */

.featured-section-AI {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 0; /* Remove gap to eliminate white space */
  background-image: url('/src/assets/1x/AI-Back.png'); /* Set background image */
  background-size: cover;
  background-position: center;
}

.featured-section-cloud {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 0; /* Remove gap to eliminate white space */
  background-image: url('/src/assets/1x/Stack-Back.png'); /* Set background image */
  background-size: cover;
  background-position: center;
}

.featured-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 0; /* Remove gap to eliminate white space */
  background-image: url('/src/assets/1x/Content-Back.png'); /* Set background image */
  background-size: cover;
  background-position: center;
}

.model-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px; /* Ensure no margin below the model */
  margin-top: 20px;
}

.featured-canvas {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  background-color: white; /* White background */
  border-radius: 15px; /* Rounded edges */
}

.featured-content {
  max-width: 600px;
  text-align: center;
  position: relative;
  z-index: 2;
  margin-top: 0; /* Ensure no margin above the content */
  margin-bottom: 20px;
  background-color: white; /* White background */
  border-radius: 15px; /* Rounded edges */
  padding: 20px; /* Add padding for spacing */
}

.featured-content h1 {
  font-size: 2rem;
  margin: 0 0 0px 0; /* Ensure no top margin */
}

.featured-content h3 {
  margin-top: 20px;
  font-size: 1.5rem;
}

.featured-content p {
  margin-bottom: 20px;
  line-height: 1.5;
}

.featured-content button {
  padding: 15px 30px; /* Increase padding for a larger button */
  margin-bottom: 10px;
  font-size: 1.125rem; /* Increase font size */
  background-color: #e37f7f; /* Coral background color */
  color: white;
  border: none;
  border-radius: 10px; /* Larger border radius for a rounded button */
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-flex; /* Use inline-flex for better alignment */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  width: 300px; /* Set a fixed width to ensure consistency */
}

.featured-content button:hover {
  background-color: #ed5a5a; /* Darker coral on hover */
}

@media (min-width: 1024px) {
  .featured-content h1 {
    font-size: 2.5rem; /* Increase font size for h1 */
  }

  .featured-content h3 {
    font-size: 1.75rem; /* Increase font size for h3 */
  }

  .featured-content p {
    font-size: 1.125rem; /* Increase font size for paragraphs */
  }

  .featured-content button {
    font-size: 1.25rem; /* Increase font size for buttons */
    padding: 20px 40px; /* Further increase padding on desktop */
    width: 400px; /* Set a larger width for desktop */
  }
}
